import React from 'react';
import {
  MDBSideNavLink,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon
} from 'mdbreact';

class SideNavigation extends React.Component {
  // render MDBSideNav Link
  rSNL(to, text) {
    console.log(to)
    return (
      <MDBSideNavLink to={to} onClick={this.props.onLinkClick}>
        {text}
      </MDBSideNavLink>
    );
  }

  render() {
    const { onLinkClick } = this.props;
    let menu = JSON.parse(localStorage.getItem('menu'));

    // console.log(menu)
    return (
      <div className='black-skin'>
        <MDBSideNav
          logo='https://spin-international.com/img/default/lg-main.png'
          bg='https://mdbootstrap.com/img/Photos/Others/sidenav4.jpg'
          mask='strong'
          fixed
          breakWidth={this.props.breakWidth}
          triggerOpening={this.props.triggerOpening}
          style={{ transition: 'padding-left .3s' }}
        >
          <MDBSideNavNav>
            
            {
              menu.map((item)=>{
                return(
                  <MDBSideNavLink topLevel to={item.route} onClick={onLinkClick}>
                    <MDBIcon icon={item.icon} />
                    {item.name}
                  </MDBSideNavLink>
                )
              })
            }
            

            {/* <MDBSideNavLink topLevel to='/calendar' onClick={onLinkClick}>
              <MDBIcon icon='calendar-check mr-2' />
              Calendar
            </MDBSideNavLink> */}

          </MDBSideNavNav>
        </MDBSideNav>
      </div>
    );
  }
}

export default SideNavigation;
