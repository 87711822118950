import React from 'react';

import './Login.css';
import {  withRouter } from "react-router-dom";

import axios from 'axios';
// import {Pagination} from 'react-laravel-paginex';
// var DataTable = require('react-data-components').DataTable;
import DataTable, { createTheme } from 'react-data-table-component';

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#fff',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

class Transaction extends React.Component {
  state = {
    collapseID: '',
    data:[],
    limit:10
  };

  async componentDidMount(){
    let url = global.variable.LINK_API + 'report/list';
    let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: {
                    limit:this.state.limit
                }
            };
    try{
      let response = await axios(options);
      let responseOK = response && response.status === 200 && response.statusText === 'OK';
      if (responseOK) {
          let data = await response.data;
          this.setState({data})
      }else{
        console.log(response);
      }
    }catch(error){
      console.log(error)
    }
  }

  getData = async (page) => {
    console.log(page)
    let url = global.variable.LINK_API + 'report/list';
    let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: {
                    limit:this.state.limit,
                    page:page.page
                }
            };
    try{
      let response = await axios(options);
      let responseOK = response && response.status === 200 && response.statusText === 'OK';
      if (responseOK) {
          let data = await response.data;
          this.setState({data})
          console.log(data)
      }else{
        console.log(response);
      }
    }catch(error){
      console.log(error)
    }
  }

  handlePageChange = page => {
    console.log(page)
    // fetchUsers(page);
    // setCurrentPage(page);
  };

  
  render() {
    //console.log(this.state.data.links);
    const renderAction =
    (val, row) =>
      <a href={`https://www.google.com/maps?q=${val['order_id']},${row}`}>
        See Detail
      </a>;
    const tableColumns = [
      { name: 'Order ID', selector: 'order_id' },
      { name: 'Status', selector: 'transaction_status' },
      { name: 'Amount', selector: 'gross_amount', className: 'text-right' },
      { name: 'Date', selector: 'created_at', },
      { name: 'Action', selector: renderAction, className: 'text-center' },
    ];
    
    return (

      <div className='classic-form-page' id='login'>

      <DataTable
          title="Transaction"
          columns={tableColumns}
          data={this.state.data.data}
          theme="solarized"
          pagination
          paginationServer={true}
          paginationTotalRows={this.state.data.last_page}
          onChangePage={page => this.getData}
        />

            
        {/* <Pagination changePage={this.getData} data={this.state.data?this.state.data:[]}/> */}
      </div>
    );
  }
}

export default withRouter(Transaction);
