import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBInput,
  MDBView,
  MDBBtn,
} from 'mdbreact';
import './Login.css';
import {  withRouter } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

export const authenticate = async () => {
    try {
     
      //console.log(this.state);
      

      const tokens = '123';

      const expires = (tokens.expires_in || 60 * 60) * 1000
      const inOneHour = new Date(new Date().getTime() + expires)

      // you will have the exact same setters in your Login page/app too
      //Cookies.set('access_token', tokens.access_token, { expires: inOneHour })
      //Cookies.set('refresh_token', tokens.refresh_token)

      Cookies.set('access_token', '333', { expires: inOneHour })
      Cookies.set('refresh_token', '222')

      return true
    } catch (error) {
      return false
    }
}

class Login extends React.Component {
  state = {
    collapseID: '',
    email: '',
    password: '',
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  handleChange = e =>{
    this.setState({[e.target.name] : e.target.value})
  }

  _login = async () => {
    const { history } = this.props;
    
    const response = await axios.post(
      global.variable.LINK_API + `auth/login`, this.state
    )
    .then(function (response) {
      // console.log(response)
      const expires = ( 60 * 60) * 1000
      const inOneHour = new Date(new Date().getTime() + expires)
      Cookies.set('access_admin', response.data.data.access_token, { expires: inOneHour })
      Cookies.set('refresh_token', '222')
      localStorage.setItem('menu', JSON.stringify(response.data.menu));
      // history.push("/dashboard")
      window.location.href="/dashboard";
    })
    .catch(function (error) {
      if (error.response) {
        // console.log(error.response.data.errors);
        // console.log(error.response.status);
        Swal.fire({
          title: 'Error',
          text: error.response.data.errors,
          icon: 'error',
          confirmButtonText: 'Close'
        })
      }
    });


    // const { history } = this.props;
    // history.push("/dashboard")
  }


  render() {
    console.log(this.props)
    
    return (
      <div className='classic-form-page' id='login'>

        <MDBView>
          <MDBMask
            className='d-flex justify-content-center align-items-center'
            overlay='stylish-strong'
          >
            <MDBContainer>
              <MDBRow>
                <MDBCol md='10' lg='6' xl='5' sm='12' className='mt-5 mx-auto'>
                  <MDBCard>
                    <MDBCardBody>
                      <div className='form-header purple-gradient'>
                        <h3>
                          Payment
                        </h3>
                      </div>
                      <MDBInput
                        name='email'
                        type='text'
                        label='Email'
                        icon='user'
                        iconClass='white-text'
                        onChange={this.handleChange}
                      />
                      <MDBInput
                        name='password'
                        type='password'
                        label='Your password'
                        icon='lock'
                        iconClass='white-text'
                        onChange={this.handleChange}
                      />
                      <div className='text-center mt-3 black-text'>
                          <MDBBtn className='purple-gradient' size='lg' onClick={this._login} >LOG IN
                          </MDBBtn>
                        <hr />
                      </div>
                      
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div>
    );
  }
}

export default withRouter(Login);
