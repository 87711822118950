import React from 'react';
import './Login.css';
import {  withRouter } from "react-router-dom";
import ReactToPrint from 'react-to-print';
var Barcode = require('react-barcode');

class Dashboard extends React.Component {
  state = {
    collapseID: ''
  };

  click = () =>  {
    
  }


  render() {
    
    return (
      <div className='classic-form-page' id='login'>

      <div ref={el => (this.componentRef = el)}>
      <table>
        <thead>
          <th>column 1</th>
          <th>column 2</th>
          <th>column 3</th>
        </thead>
        <tbody>
          <tr>
            <td>data 1</td>
            <td>data 2</td>
            <td><Barcode  width={1} height={50} value="http://github.com/kciter" /></td>
          </tr>
        </tbody>
      </table>
      </div>
        

        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <a href="#">Print this out!</a>;
          }}
          content={() => this.componentRef}
        />
      </div>
    );
  }
}

export default withRouter(Dashboard);
