import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import NumberFormat from 'react-number-format';
import { subDays, format } from 'date-fns';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCol, MDBRow, MDBSelect } from 'mdbreact';

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
// import "./styles.css";

// const removeItem = (array, item) => {
//   const newArray = array.slice();
//   newArray.splice(newArray.findIndex(a => a === item), 1);

//   return newArray;
// };

const Payout = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [optionStatus, setOptionStatus] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusSelected, setStatusSelected] = useState([]);
  const [selectedPayout, setSelectedPayout] = useState([]);
  const [updatePayout, setUpdatePayout] = useState([]);


  const [postData, setPostData] = useState({status: 2});
  const [postDataPayout, setPostDataPayout] = useState({payout_id: [], status: 3});
  // const [deleted, setDeleted] = useState([]);

  const fetchUsers = async (page, size = perPage) => {
    setLoading(true);

    const response = await axios.post(
      global.variable.LINK_API + `report/list/payout?page=${page}&per_page=${size}&delay=1`, postData, global.variable.HEADERS_BEARER
    );

    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  };

  const fetchParams = async () => {
    const response = await axios.get(
      global.variable.LINK_API + `param/status_transaction`
    );
    setOptionStatus(response.data);

  };

  useEffect(() => {
    if(postDataPayout.payout_id.length===0){
      fetchUsers(1);
      fetchParams();
    }else{
      console.log(postDataPayout.payout_id)
      fetchUpdatePayout();
    }
  }, [postData, postDataPayout]);// eslint-disable-line react-hooks/exhaustive-deps

  const fetchUpdatePayout = async () => {
    const response = await axios.post(
      global.variable.LINK_API + `report/update/payout`, postDataPayout, global.variable.HEADERS_BEARER
    );
    setUpdatePayout(response.data)
    toggle()
    fetchUsers(1);
    setSelectedPayout([])
  }
  
  const handleChangeOrderId =  orderId => {
    setPostDataPayout({payout_id:orderId, status:3})
  };

  const handleRejectOrderId =  orderId => {
    setPostDataPayout({payout_id:orderId, status:0})
  };

  const columns = useMemo(
    () => [
      {
        name: "Payout ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Rekening Tujuan",
        cell: row => <div>{row.bank_id +'-'+ row.no_rek +' (' + row.atas_nama + ')'}</div>,
        sortable: true,
      },
      {
        name: "Amount",
        cell: row => <NumberFormat value={row.paid_amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />,
        sortable: true,
        right: true,
      },
      {
        name: "Tanggal Request",
        cell: row => row.request_date?row.request_date: row.request_date,
        sortable: true
      },
      {
        name: "Action",
        cell: row => <MDBRow><button class="btn btn-danger btn-rounded btn-sm"  onClick={() => handleRejectOrderId([row.id])}>Reject</button>
                      <button class="btn btn-primary btn-rounded btn-sm"  onClick={() => handleChangeOrderId([row.id])}>Approve</button></MDBRow>
      }
    ],
    []// eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePageChange = page => {
    fetchUsers(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  const toggle = () => {
    setModal(!modal)
  }

  const tableData = {
    columns,
    data,
  };

  const conditionalRowStyles = [
    {
      when: row => row.toggleSelected,
      style: {
        backgroundColor: "green",
        userSelect: "none"
      }
    }
  ];

  const handleRowClicked = row => {
    const updatedData = data.map(item => {
      if(selectedPayout.indexOf(row.id) > -1){
        setSelectedPayout(selectedPayout.filter(item => item!==row.id));
      }else{
        setSelectedPayout([...selectedPayout, row.id]);
      }
      if (row.id !== item.id) {
        return item;
      }

      return {
        ...item,
        toggleSelected: !item.toggleSelected
      };
    });

    setData(updatedData);
  };

  return (
    <MDBContainer style={{marginTop:-50}}>
      <DataTableExtensions
        {...tableData}>
      <DataTable
        title={<div style={{display:'flex', flexDirection:'row', width:'140%'}}>
                <div>Approval Payout</div>
                <div style={{marginLeft:'auto', align:'right', }}>
                  <button class="btn btn-primary btn-rounded btn-sm" onClick={() => handleChangeOrderId(selectedPayout)}>  
                    Approve {selectedPayout.length} payout terpilih
                  </button>
                </div>
              </div>}
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onRowClicked={handleRowClicked}
        conditionalRowStyles={conditionalRowStyles}
      />
      </DataTableExtensions>

      <MDBModal isOpen={modal} toggle={toggle} >
        <MDBModalHeader toggle={toggle}>{updatePayout.message}</MDBModalHeader>
        <MDBModalBody className="text-center">
            {updatePayout.desc}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn class="btn btn-secondary btn-rounded btn-sm" onClick={toggle}>Close</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      
    </MDBContainer>
  );
};

export default Payout;