import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { MDBCard, MDBCardTitle, MDBCardText, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import NumberFormat from 'react-number-format';

const TransactionDetail = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async (id) => {
    // console.log(id)
    setLoading(true);

    const response = await axios.get(
      global.variable.LINK_1TOKO + `payment/detail_transaction/`+id,
      global.variable.HEADERS_1TOKO
    );
    // console.log(response)
    setData(response.data);
    setLoading(false);

    console.log(response.data.invoice[0].payment_type)
  };

  useEffect(() => {
    fetchUsers(props.match.params.id);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MDBContainer >
      <MDBCard className="card-body">
      <MDBCardTitle>Informasi Pembayaran</MDBCardTitle>
      {data!==null||data!==[]?
      <MDBRow className="mb-4">
        <MDBCol sm="3">
          <MDBCard className="card-body" style={{ marginTop: "1rem" }}>
            <MDBCardTitle tag="h6">Order ID</MDBCardTitle>
            <MDBCardText>{data.order_id}</MDBCardText>
          </MDBCard>
        </MDBCol>
        <MDBCol sm="3">
          <MDBCard className="card-body" style={{ marginTop: "1rem" }}>
            <MDBCardTitle tag="h6">Amount</MDBCardTitle>
            <MDBCardText><NumberFormat value={data.gross_amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /></MDBCardText>
          </MDBCard>
        </MDBCol>
        <MDBCol sm="3">
          <MDBCard className="card-body" style={{ marginTop: "1rem" }}>
            <MDBCardTitle tag="h6">Payment Method</MDBCardTitle>
            <MDBCardText>{data.invoice?data.invoice[0].payment_type:''}</MDBCardText>
          </MDBCard>
        </MDBCol>
        <MDBCol sm="3">
          <MDBCard className="card-body" style={{ marginTop: "1rem" }}>
            <MDBCardTitle tag="h6">Status Transaction</MDBCardTitle>
            <MDBCardText>{data.invoice?data.invoice[0].status.name:''}</MDBCardText>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      :
      <MDBRow className="mb-4">
        <MDBCol sm="3">
            <MDBCardTitle tag="h6">Data tidak ditemukan</MDBCardTitle>
        </MDBCol>
      </MDBRow>}
      </MDBCard>
    </MDBContainer>
  );
};

export default TransactionDetail;