import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import NumberFormat from 'react-number-format';
// import "./styles.css";

// const removeItem = (array, item) => {
//   const newArray = array.slice();
//   newArray.splice(newArray.findIndex(a => a === item), 1);

//   return newArray;
// };

const AdvancedPaginationTable = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // const [deleted, setDeleted] = useState([]);

  const fetchUsers = async (page, size = perPage) => {
    setLoading(true);

    const response = await axios.post(
      global.variable.LINK_API + `report/list?page=${page}&per_page=${size}&delay=1`, {}, global.variable.HEADERS_BEARER
    );

    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  };

  useEffect(() => {
    console.log()
    fetchUsers(1);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  // const handleDelete = useCallback(
  //   row => async () => {
  //     await axios.delete(`https://reqres.in/api/users/${row.id}`);
  //     const response = await axios.get(
  //       `https://reqres.in/api/users?page=${currentPage}&per_page=${perPage}`
  //     );

  //     setData(removeItem(response.data.data, row));
  //     setTotalRows(totalRows - 1);
  //   },
  //   [currentPage, perPage, totalRows]
  // );


  const handleChangeOrderId = orderId => {
    props.history.push('/transactiondetail/'+orderId)
  };

  const columns = useMemo(
    () => [
      {
        name: "Order ID",
        cell: row => <div style={{color:'blue',}} onClick={() => handleChangeOrderId(row.order_id)}>{row.order_id}</div>,
        sortable: true
      },
      {
        name: "Merchant",
        cell: row => row.merchant_name,
        sortable: true
      },
      {
        name: "Status",
        cell: row => <div style={{backgroundColor:row.transaction_status==='settlement'||row.transaction_status==='capture'?'green':row.transaction_status==='expire'||row.transaction_status==='deny'?'red':'brown', padding:5, borderRadius:10, color: 'white'}} >{row.transaction_status}</div>,
        sortable: true
      },
      {
        name: "Amount",
        cell: row => <NumberFormat value={row.gross_amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />,
        sortable: true
      },
      {
        name: "Tanggal",
        cell: row => row.transaction_date?row.transaction_date: row.created_at,
        sortable: true
      },
      // {
      //   name: "Action",
      //   cell: row => <button onClick={() => handleChangeOrderId(row.order_id)}>See Detail</button>
      // }
    ],
    []// eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePageChange = page => {
    fetchUsers(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  return (
    <DataTable
      title="Transaction"
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
    />
  );
};

export default AdvancedPaginationTable;