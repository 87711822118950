import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import "./index.css";
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import RoutesWithNavigation from './components/pages/RoutesWithNavigation';
import Cookies from 'js-cookie';

export const getAccessToken = () => Cookies.get('access_admin')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

export const authenticate = async () => {
  if (getRefreshToken()) {
    try {
      const tokens = Cookies.get('access_admin');

      const expires = (tokens.expires_in || 60 * 60) * 1000
      const inOneHour = new Date(new Date().getTime() + expires)

      Cookies.set('access_admin', tokens, { expires: inOneHour, secure: true, HttpOnly:true })
      Cookies.set('refresh_token', 'refresh_token')

      return true
    } catch (error) {
      redirectToLogin()
      return false
    }
  }

  // redirectToLogin()
  return false
}

const redirectToLogin = () => {
  //history.push('/')// if your Login page is inside the same app
  window.location.href="/";
}

class App extends Component{

  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      windowWidth: 0,
      currentPage: '',
      sideNavToggled: false,
      breakWidth: 800,
      authenticate: true,
    };
  }

  componentDidMount() {
    if(window.location.pathname!=='/'){
      authenticate().then(isAuthenticated => {
          this.setState({authenticate:isAuthenticated});
      })
    }
}


  componentDidUpdate(prevProps, nextProps, snapshot) {
    // if (this.props.location.pathname !== prevProps.location.pathname) {
    //   // this.assessLocation(this.props.location.pathname);
    // }
  }
  // componentDidMount() {
  //   this.handleResize();
  //   window.addEventListener('resize', this.handleResize);
  //   this.assessLocation(this.props.location.pathname);
  // }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  render() {
    return(
      <BrowserRouter>
      <Switch>
        <Route path='/' exact component={Login} />
        <Route path='/register' exact component={Register} />
        {this.state.authenticate&&window.location.pathname!=='/'?
        <RoutesWithNavigation/>
        :
        window.location.pathname!=='/'?
        redirectToLogin():''}
      </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
