import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import NumberFormat from 'react-number-format';
import { subDays, format } from 'date-fns';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCol, MDBRow, MDBSelect } from 'mdbreact';

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import style from "./css/printInvoice.module.css";
import lunas from "../assets/images/LUNAS.jpg";
import batal from "../assets/images/DIBATALKAN.jpg";

// import "./styles.css";

// const removeItem = (array, item) => {
//   const newArray = array.slice();
//   newArray.splice(newArray.findIndex(a => a === item), 1);

//   return newArray;
// };

const ReportInvoice = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [optionStatus, setOptionStatus] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusSelected, setStatusSelected] = useState([]);
  const [modalInvoice, setModalInvoice] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const total_product = invoice?.data?.detail_checked.reduce(
    ({ t, p, w }, item) => {
      const { varian, product } = item;
      let temp_qty = parseInt(item.qty);
      let weight = parseInt(product.weight) * temp_qty;
      let temp_price = parseInt(varian ? varian.fix_price : product.fix_price);
      temp_price = temp_price * temp_qty;
      return { t: t + temp_qty, p: p + temp_price, w: w + weight };
    },
    { t: 0, p: 0, w: 0 }
  );
  const total =
    total_product?.p + parseInt(invoice?.courier_amount) + parseInt(invoice?.courier_insurance_amount);
  const final_total = total - parseInt(invoice?.tr_invoice?.promo_detail ? invoice?.tr_invoice?.total_discount : 0);

  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [postData, setPostData] = useState(
    {
      start_date: subDays(new Date(), 30),
      end_date: new Date(),
      status: [],
    }
  );
  // const [deleted, setDeleted] = useState([]);

  const fetchUsers = async (page, size = perPage) => {
    setLoading(true);

    const response = await axios.post(
      global.variable.LINK_API + `report/list/invoice?page=${page}&per_page=${size}&delay=1`, postData, global.variable.HEADERS_BEARER
    );

    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  };

  const fetchParams = async () => {
    const response = await axios.get(
      global.variable.LINK_API + `param/status_transaction`
    );
    setOptionStatus(response.data);

  };

  useEffect(() => {
    fetchUsers(1);
    fetchParams();
  }, [postData]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeOrderId = orderId => {
    props.history.push('/transactiondetail/'+orderId)
  };

  const columns = useMemo(
    () => [
      {
        name: "Invoice ID",
        cell: row => <div style={{color:'blue',}} onClick={() => {setInvoice(row); toggleInvoice()}}>{row.invoice_id}</div>,
        sortable: true,
      },
      {
        name: "Status",
        cell: row => <div style={{backgroundColor:row.invoice_status==='5'?'green':row.invoice_status==='99'||row.invoice_status==='98'?'red':'brown', padding:5, borderRadius:10, color: 'white'}} >{row.name}</div>,
        sortable: true,
      },
      {
        name: "Amount",
        cell: row => <NumberFormat value={row.gross_amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />,
        sortable: true,
        right: true,
      },
      {
        name: "Tanggal",
        cell: row => row.transaction_date?row.transaction_date: row.created_at,
        sortable: true
      },
      // {
      //   name: "Action",
      //   cell: row => <button onClick={() => handleChangeOrderId(row.order_id)}>See Detail</button>
      // }
    ],
    []// eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePageChange = page => {
    fetchUsers(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  const toggle = () => {
    setModal(!modal)
  }

  const toggleInvoice = () => {
    setModalInvoice(!modalInvoice)
  }


  const getValueSelect = (selectValue) =>{
    console.log(selectValue)
    setStatusSelected(selectValue)
  }

  const save = () => {
    // console.log(statusSelected, state)
    setPostData({
      start_date: state[0].startDate,
      end_date : state[0].endDate,
      status: statusSelected
    })
    setModal(!modal)
  }

  const tableData = {
    columns,
    data,
  };
  var background_img = ["99"].includes(invoice.invoice_status) ? style.cancel : style.success;
  var background_img_inline = ["99"].includes(invoice.invoice_status)? batal: lunas;
  return (
    <MDBContainer style={{marginTop:-50}}>
      <DataTableExtensions
        {...tableData}>
        <DataTable
          title={<div style={{display:'flex', flexDirection:'row', width:'105%'}}>
                  <div>Report by Invoice dari tanggal {format(state[0].startDate, 'dd-MM-yyyy')} sampai tanggal {format(state[0].endDate, 'dd-MM-yyyy')}</div>
                  <div style={{marginLeft:'auto', align:'right', }}>
                    <button class="btn btn-primary btn-rounded btn-sm" onClick={toggle}>  
                    Filter
                    </button>
                  </div>
                </div>}
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows={true}
          onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
        />
      </DataTableExtensions>


      <MDBModal isOpen={modal} toggle={toggle} size="lg">
        <MDBModalHeader toggle={toggle}>Filter</MDBModalHeader>
        <MDBModalBody className="text-center">
          <DateRange
            editableDateInputs={true}
            onChange={item => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
          /> 
          <MDBRow>
            <MDBCol md="6" className="ml-5">
              <MDBSelect
                multiple
                options={optionStatus}
                selected="Pilih Status"
                selectAll
                getValue={getValueSelect}
              />
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn class="btn btn-secondary btn-rounded btn-sm" onClick={toggle}>Close</MDBBtn>
          <MDBBtn class="btn btn-primary btn-rounded btn-sm" onClick={save}>Save changes</MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      <MDBModal isOpen={modalInvoice} toggle={toggleInvoice} size="xl">
        <MDBModalHeader toggle={toggleInvoice}>Invoice {invoice?.invoice_id}</MDBModalHeader>
        <MDBModalBody className="text-center">
        <div className={`${style.container} ${background_img}`} style={{backgroundImage: `url(${background_img_inline})`, backgroundRepeat: 'no-repeat', backgroundSize: '25rem', backgroundPosition: 'center'}}>
          {/*  header */}
          <div className={style.inv_header}>
            <img src={`https://api.image.v2.1toko.com/cache/logo/200/50/0/logoungu_1toko.png`} />
            <div className={style.inv_title}>
              <div className={style.inv_text}>invoice</div>
              <div className={style.inv_no}>{invoice?.invoice_id}</div>
            </div>
          </div>
          {/* dtl inv */}
          <div className={style.dtl_inv_container}>
            <table className={style.dtl_inv_tbl}>
              <tbody>
                <tr>
                  <th colSpan={3}>diterbitkan atas nama</th>
                </tr>
                <tr>
                  <td className={style.text_l}>Penjual</td>
                  <td>:</td>
                  <td className={style.text_bold}>{invoice?.tr_invoice?.seller_details?.name}</td>
                </tr>
              </tbody>
            </table>
            <table className={style.dtl_inv_tbl}>
              <tbody>
                <tr>
                  <th colSpan={3}>untuk</th>
                </tr>
                <tr>
                  <td className={style.text_l}>Pembeli</td>
                  <td>:</td>
                  <td className={style.text_bold}>{invoice?.tr_invoice?.customer_details?.first_name}</td>
                </tr>
                <tr>
                  <td className={style.text_l}>Tanggal Pembelian</td>
                  <td>:</td>
                  <td className={style.text_bold}>{invoice?.transaction_date}</td>
                </tr>
                <tr>
                  <td className={style.text_l}>Alamat Pengiriman</td>
                  <td>:</td>
                  <td>
                    <div className={style.text_name_phone}>
                      <div id={style.name}>{`${invoice?.tr_invoice?.customer_details?.first_name}`}</div>
                      <div>{`( ${invoice?.tr_invoice?.customer_details?.phone} )`}</div>
                    </div>
                    <div className={style.address}>{invoice?.tr_invoice?.customer_details?.address?.address + ', ' + invoice?.tr_invoice?.customer_details?.address?.zip?.sub_district + ', ' + invoice?.tr_invoice?.customer_details?.address?.zip?.district + ', ' + invoice?.tr_invoice?.customer_details?.address?.zip?.sub_province + ', ' + invoice?.tr_invoice?.customer_details?.address?.zip?.province + ', ' + invoice?.tr_invoice?.customer_details?.address?.zip?.zip }</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* dtl product */}
          <table className={style.dtl_product_tbl}>
            <tbody>
              <tr>
                <th className={style.product_dtl_container}>info produk</th>
                <th>jumlah</th>
                <th>harga satuan</th>
                <th style={{ paddingRight: "0.5rem" }}>total harga</th>
              </tr>
              {invoice?.data?.detail_checked.map((data, idx) => {
                const { varian, product } = data;
                const price = varian ? varian.fix_price : product.fix_price;
                const name = `${product.name} ${varian?.name1 ?? ""} ${
                  varian?.name2 ?? ""
                }`;
                const qty = parseInt(data.qty);
                const weight = product.weight;
                const subtotal = qty * parseInt(price);
                return (
                  <tr key={`item_${idx}`}>
                    <td className={style.product_dtl_container}>
                      <div id={style.name}>{name}</div>
                      <div id={style.weight}>{`Berat : ${weight} gr`}</div>
                    </td>
                    <td>{qty}</td>
                    <td>{<NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />}</td>
                    <td style={{ paddingRight: "0.5rem" }}>
                      {<NumberFormat value={subtotal} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* dtl payment */}
          <div className={style.payment_dtl}>
            <div className={style.payment_dtl_item_black}>
              <div>{`TOTAL HARGA (${total_product?.t} BARANG)`}</div>
              <div>{<NumberFormat value={total_product?.p} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />}</div>
            </div>
            <div className={style.payment_dtl_item}>
              <div>{`Total Ongkos Kirim (${total_product?.w} gr)`}</div>
              <div>{<NumberFormat value={invoice?.courier_amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />}</div>
            </div>
            {parseInt(invoice?.courier_insurance_amount) > 0 && (
              <div className={style.payment_dtl_item}>
                <div>{`Biaya Asuransi Pengiriman`}</div>
                <div>{<NumberFormat value={invoice?.courier_insurance_amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />}</div>
              </div>
            )}
            <div className={style.payment_dtl_item_line}>
              <div>{`TOTAL BELANJA`}</div>
              <div>{<NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />}</div>
            </div>
            {parseInt(invoice?.tr_invoice?.total_discount) > 0 && invoice?.tr_invoice?.promo_detail?.promo_code && (
              <div className={style.payment_dtl_item}>
                <div>{`Diskon referral ${invoice?.tr_invoice?.promo_detail?.promo_code}`}</div>
                <div>{`- ${<NumberFormat value={invoice?.tr_invoice?.total_discount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />}`}</div>
              </div>
            )}
            <div className={style.payment_dtl_item_line}>
              <div>{`TOTAL TAGIHAN`}</div>
              <div>{<NumberFormat value={final_total} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />}</div>
            </div>
          </div>
          {/* dtl lainnya */}
          <div className={style.other_container}>
            <div className={style.other_container_item}>
              <div id={style.label}>Kurir:</div>
              <div id={style.fill}>{invoice?.courier_name}</div>
            </div>
            <div className={style.other_container_item}>
              <div id={style.label}>Metode Pembayaran:</div>
              <div id={style.fill}>{invoice?.tr_invoice?.transaction_details?.payment_type?.name}</div>
            </div>
          </div>
          
        </div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn class="btn btn-secondary btn-rounded btn-sm" onClick={toggleInvoice}>Close</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

export default ReportInvoice;