import React from 'react';
import './Login.css';
import {  withRouter } from "react-router-dom";

class Dashboard extends React.Component {
  state = {
    collapseID: ''
  };

  


  render() {
    
    return (
      <div className='classic-form-page' id='login'>

        dashboard
      </div>
    );
  }
}

export default withRouter(Dashboard);
