import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import Transaction from './Transaction';
import Calendar from './Calendar';
import Trans from './Trans';
import TransactionDetail from './TransactionDetail';
import ReportInvoice from './ReportInvoice';
import ReportInvoiceFull from './ReportInvoiceFull';
import Refund from './Refund';
import CheckPayout from './CheckPayout';
import ApprovePayout from './ApprovePayout';
import ReportPayout from './ReportPayout';
import ReportPayment from './ReportPayment';

const fourtOFour = () => <h1 className="text-center">404</h1>

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path='/dashboard' exact component={Dashboard} />
        <Route path='/transaction_1' exact component={Transaction} />
        <Route path='/transaction' exact component={Trans} />
        <Route path='/transactiondetail/:id' exact component={TransactionDetail} />
        <Route path='/reportinvoice' exact component={ReportInvoice} />
        <Route path='/reportinvoicefull' exact component={ReportInvoiceFull} />
        <Route path='/refund' exact component={Refund} />
        <Route path='/checkpayout' exact component={CheckPayout} />
        <Route path='/approvepayout' exact component={ApprovePayout} />
        <Route path='/reportpayout' exact component={ReportPayout} />
        <Route path='/reportpembayaran' exact component={ReportPayment} />
        <Route path='/calendar' exact component={Calendar} />

        {/* <Route path='/transaction' exact component={Transaction} /> */}
        <Route component={fourtOFour} />
      </Switch>
    );
  }
}

export default Routes;