import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import NumberFormat from 'react-number-format';
import { subDays, format } from 'date-fns';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCol, MDBRow, MDBSelect } from 'mdbreact';

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
// import "./styles.css";

// const removeItem = (array, item) => {
//   const newArray = array.slice();
//   newArray.splice(newArray.findIndex(a => a === item), 1);

//   return newArray;
// };

const ReportInvoice = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [optionStatus, setOptionStatus] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusSelected, setStatusSelected] = useState([]);
  const [calculationData, setCalculationData] = useState({
    gross_amount: 0,
    admin_fee:0,
    courier_amount: 0,
    net_amount: 0,
    remaining_amount: 0,
    paid_amount: 0,
    refund_amount: 0,
  });
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [postData, setPostData] = useState(
    {
      start_date: subDays(new Date(), 30),
      end_date: new Date(),
      status: [],
    }
  );
  // const [deleted, setDeleted] = useState([]);

  const fetchUsers = async (page, size = perPage) => {
    setLoading(true);

    const response = await axios.post(
      global.variable.LINK_API + `report/list/invoice?page=${page}&per_page=${size}&delay=1`, postData, global.variable.HEADERS_BEARER
    );

    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  };

  const fetchParams = async () => {
    const response = await axios.get(
      global.variable.LINK_API + `param/status_transaction`
    );
    setOptionStatus(response.data);

  };

  const fetchCalculation = async () => {
    const response = await axios.post(
      global.variable.LINK_API + `report/invoice/calculation`, postData, global.variable.HEADERS_BEARER
    );
    setCalculationData(response.data);

  };

  useEffect(() => {
    fetchUsers(1);
    fetchParams();
    fetchCalculation();
  }, [postData]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeOrderId = orderId => {
    props.history.push('/transactiondetail/'+orderId)
  };

  const columns = useMemo(
    () => [
      {
        name: "Invoice ID",
        selector: "invoice_id",
        sortable: true,
      },
      {
        name: "Status",
        cell: row => <div style={{backgroundColor:row.invoice_status==='5'?'green':row.invoice_status==='99'||row.invoice_status==='98'?'red':'brown', padding:5, borderRadius:10, color: 'white'}} >{row.name}</div>,
        sortable: true,
      },
      {
        name: "Amount",
        cell: row => <NumberFormat value={row.gross_amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />,
        sortable: true,
        right: true,
      },
      {
        name: "Tanggal",
        cell: row => row.transaction_date?row.transaction_date: row.created_at,
        sortable: true
      },
      // {
      //   name: "Action",
      //   cell: row => <button onClick={() => handleChangeOrderId(row.order_id)}>See Detail</button>
      // }
    ],
    []// eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePageChange = page => {
    fetchUsers(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  const toggle = () => {
    setModal(!modal)
  }


  const getValueSelect = (selectValue) =>{
    console.log(selectValue)
    setStatusSelected(selectValue)
  }

  const save = () => {
    // console.log(statusSelected, state)
    setPostData({
      start_date: state[0].startDate,
      end_date : state[0].endDate,
      status: statusSelected
    })
    setModal(!modal)
  }

  const tableData = {
    columns,
    data,
  };

  return (
    <MDBContainer style={{marginTop:-50}}>
      <DataTableExtensions
        {...tableData}>
        <DataTable
          title={
            <div style={{display:'flex', flexDirection:'column', width:'105%'}}>
              <div style={{display:'flex', flexDirection:'row'}}>
                <div>Report by Invoice dari tanggal {format(state[0].startDate, 'dd-MM-yyyy')} sampai tanggal {format(state[0].endDate, 'dd-MM-yyyy')}</div>
                <div style={{marginLeft:'auto', align:'right', }}>
                  <button class="btn btn-primary btn-rounded btn-sm" onClick={toggle}>  
                  Filter
                  </button>
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row', fontSize:14, marginTop:5}}>
                <div>Jumlah Uang Masuk</div>
                <div style={{marginLeft:'auto', align:'right', marginRight:10}}>
                  <NumberFormat value={calculationData.gross_amount}displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row', fontSize:14, marginTop:5}}>
                <div>Jumlah Admin Fee (ke channel pembayaran)</div>
                <div style={{marginLeft:'auto', align:'right', marginRight:10}}>
                  <NumberFormat value={calculationData.admin_fee}displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row', fontSize:14, marginTop:5}}>
                <div>Jumlah Uang Kurir + Asuransi Kurir</div>
                <div style={{marginLeft:'auto', align:'right', marginRight:10}}>
                  <NumberFormat value={calculationData.courier_amount}displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row', fontSize:14, marginTop:5}}>
                <div>Jumlah Uang Bersih</div>
                <div style={{marginLeft:'auto', align:'right', marginRight:10}}>
                  <NumberFormat value={calculationData.net_amount}displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row', fontSize:14, marginTop:5}}>
                <div>Jumlah Uang Potongan SPIN</div>
                <div style={{marginLeft:'auto', align:'right', marginRight:10}}>
                  <NumberFormat value={calculationData.remaining_amount}displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row', fontSize:14, marginTop:5}}>
                <div>Jumlah Uang Dibayarkan ke Merchant</div>
                <div style={{marginLeft:'auto', align:'right', marginRight:10}}>
                  <NumberFormat value={calculationData.paid_amount}displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row', fontSize:14, marginTop:5}}>
                <div>Jumlah Uang Refund</div>
                <div style={{marginLeft:'auto', align:'right', marginRight:10}}>
                  <NumberFormat value={calculationData.refund_amount}displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                </div>
              </div>
            </div>
          }
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows={true}
          onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
        />
      </DataTableExtensions>


      <MDBModal isOpen={modal} toggle={toggle} size="lg">
        <MDBModalHeader toggle={toggle}>Filter</MDBModalHeader>
        <MDBModalBody className="text-center">
          <DateRange
            editableDateInputs={true}
            onChange={item => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
          /> 
          <MDBRow>
            <MDBCol md="6" className="ml-5">
              <MDBSelect
                multiple
                options={optionStatus}
                selected="Pilih Status"
                selectAll
                getValue={getValueSelect}
              />
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn class="btn btn-secondary btn-rounded btn-sm" onClick={toggle}>Close</MDBBtn>
          <MDBBtn class="btn btn-primary btn-rounded btn-sm" onClick={save}>Save changes</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

export default ReportInvoice;