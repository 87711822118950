import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";
import Global from './components/constants/Global';
import Moment from 'moment';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';

import registerServiceWorker from './registerServiceWorker';


global.variable = Global;
global.moment = Moment;

const Apps = () => {
    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
    return (
      <div>
        {!isLatestVersion && (
          <p>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                emptyCacheStorage();
              }}
            >
              Update version
            </a>
          </p>
        )}<App/>
      </div>
    );
  };
  
  ReactDOM.render(
    <ClearCacheProvider duration={3600000}>
      <Apps />
    </ClearCacheProvider>,
    document.getElementById('root')
  );
// ReactDOM.render( <App /> , document.getElementById('root'));

registerServiceWorker();